function getActionUrl(button) {
    switch (button.id) {
      case 'generate-keywords':
        return '/generate_keywords';
      case 'update-keywords':
        return '/update_keywords';
      case 'generate-titles':
        return '/generate_titles';
      case 'generate-title-ideas':
            return '/generate_title_ideas';
      case 'generate-outline':
        return '/generate_outline';
      case 'generate-outline-ideas':
        return '/generate_outline_ideas';
      case 'generate-content':
        return '/generate_content';
        case 'generate-content-bulk':
          return '/generate_content_bulk';
      default:
        return '';
    }
}

// show loading info UNTIL the page submits
// show overlay with message 1) Generating your post, 2) Selecting keywords, 3) Gathering title suggestions
function handleAutoPostSubmit(event) {
    console.log('autoPostForm submitted')
    siteUi.startProgressBar()    
    const messages = [
        "Creating your post...",
        "Generating great keywords...",
        "Gathering title suggestions...",
        "It's almost ready...",
        "Our server must be overwhelmed...should be just a few more seconds!",
        "Think of how long it takes to actually write a post by hand!",
        "...just about done!",
        "Polishing your post...",
        "Checking for spelling and grammar...",
        "Adding a touch of magic...",
        "Optimizing for search engines...",
        "Making sure your post shines...",
        "Finalizing the details...",
        "Double-checking everything...",
        "One last step..."
      ];
      
      let delay = 0;
      for (const message of messages) {
        setTimeout(() => {
          siteUi.showPageOverlay(message);
        }, delay);
        delay += 1500; // Adjust the delay as needed
      }
}

// called AFTER the data is returned from the server (via a POST request)
function handleGenerateResponse(button, data, keywordsInput, formData) {
  console.log(`handleGenerateResponse: ${button.id}`, data)
    if (button.id === 'generate-keywords' || button.id === 'update-keywords') {
      keywordsInput.value = data.tags;
      posts.renderKeywords(data.tags);
    } else if (button.id === 'generate-titles') {
      const postId = formData.get('post[id]');
      console.log(postId);
      posts.renderTitles(data.titles, postId);
    } else if (button.id === 'generate-title-ideas') {
        posts.renderTitles(data.titles);
    } else if (button.id === 'generate-outline-ideas') {
        posts.renderOutline(data.outline);
    } else if (button.id === 'generate-outline') {
      posts.renderOutline(data.outline);
    } else if (button.id === 'generate-content') {
      // Handle 'generate-content' response
    } else if (button.id === 'generate-content-bulk') {
      // Handle 'generate-content' response
      const sections = data.sections;
      
      // for(sectionId of sectionIds) {console.log(sectionId)}
      //for(let i = 0; i < sectionIds.length; i++) {
      //}
      sections.forEach((section) => {
        console.log(`Generating section: ${section.title}`)
        posts.generateSectionContent(section)
        let generatingHtml = `<div class="d-flex align-items-center">Generating <div class="sectionGenerateSpinner spinner-grow spinner-grow-sm text-dark mx-1" role="status"><span class="visually-hidden">Generating...</span></div></div>`;

        $(`.editable-section[data-id=${section.id}]`).find('.section-generate-btn').html(generatingHtml)
      })

      //posts.generateSections(data.ids)

    }
  }

  // for initializing title generation or outline generation from javascript
  function autoRunGeneration(baseUrl,generateType,formData=null) {
    const elementId = `generate-${generateType}`
    let button = document.getElementById(elementId)
    if(!button) {
      button = {id:elementId}
    }
    const actionUrl = getActionUrl(button);
    const initialUrl = `${baseUrl}${actionUrl}`;
    siteUi.startProgressBar()
    if(formData==null) {
      formData = new FormData(document.querySelector('#postBuilder'));
    }
    const additionalForm = document.querySelector('.mainPostForm');
    
    if(['titles','outline'].includes(generateType) && additionalForm) {
      const additionalTitle = additionalForm.querySelector('input[name="post[title]"]').value;
      formData.append('post[title]', additionalTitle);
    }
    doGeneratePostAction(formData, button, initialUrl);
  }

  // show loading info, submit form data, handle the response
  function handleGeneratePostFormSubmit(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  
    const form = event.target;
    const formData = new FormData(form);
     //now get the main Post form
     const additionalForm = document.querySelector('.mainPostForm');

    // Get the value from the additional form and append it to the FormData
    const additionalTitle = additionalForm.querySelector('input[name="post[title]"]').value;
    formData.append('post[title]', additionalTitle);
    // Log the updated FormData object
    for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
    }
    if(document.activeElement.id == 'generate-titles')
    {
      posts.renderTitlesNotice()
    }
    else if(document.activeElement.id == 'generate-outline')
    {
      posts.renderOutlineNotice()
    }
    else if(document.activeElement.id == 'generate-keywords')
    {
      posts.renderKeywordsNotice()
    }

    url = form.getAttribute('action') + getActionUrl(document.activeElement);
    // second param is the button const button = document.activeElement;
    doGeneratePostAction(formData, document.activeElement, url)
}


// takes formData, button and url, performs a POST request then handles the response
function doGeneratePostAction(formData, button, providedUrl) {
    console.log('inside doGeneratePostAction')
    console.log(`providedUrl: ${providedUrl}`)
    const progressBarContainer = document.getElementById('progress-bar-container');
    siteUi.startProgressBar(progressBarContainer)  // Trigger the loading effects
    const finalUrl = providedUrl || form.getAttribute('action') + getActionUrl(button);
    const keywordsInput = document.querySelector('tags.tags');
    console.log(formData)
      // Perform an AJAX request to the specified URL
      fetch(finalUrl, {
        method: 'POST',
        headers: {
          //'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: formData, // JSON.stringify(formData), // JSON.stringify(Object.fromEntries(formData.entries())), // formData,
        redirect: 'follow',
      })
        .then(response => response.json())
        .then(data => {
          console.log('handling response')
          console.log(data)
          handleGenerateResponse(button, data, keywordsInput, formData)

          // Hide the loading effects in case of an error
          window.siteUi.hideLoadingEffects();
          progressBarContainer.style.display = 'none';
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }

  function loadOutlineFromId(postId, data={start:1}) {
    console.log(`loadOutlineFromId: ${postId}`)
    const refreshingMessage = { header: 'Generating outline', message: `The outline is being generated`, type: 'starting', imageUrl: null } 
    let showNotice = siteUi.showNotification(refreshingMessage,7000);         
    const progressBarContainer = document.getElementById('progress-bar-container');
    progressBarContainer.style.display = 'block';

    let url = `/posts/${postId}/generate_outline.js`

    fetch(url, {
    method: 'POST',
    headers: {
        'Accept': 'application/javascript',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    },
    //body: JSON.stringify(formData),
    body: JSON.stringify(data), // NOTE: this is not showing up on the server
  })
  .then(response => response.text()) // assuming the response is text
  .then(script => {
        // Evaluate the received JavaScript
        console.log(`script: ${script}`)
        eval(script);
        siteUi.hideNotification(showNotice)
        const refreshingDoneMessage = { header: 'Generation done', message: 'Outline is ready...request changes, reorder, remove sections or save!', type: 'success', imageUrl: null } 
        siteUi.showNotification(refreshingDoneMessage);           
        progressBarContainer.style.display = 'none';
    })
    .catch(error => {
      console.error('Error:', error);
      siteUi.hideNotification(showNotice)
      const refreshingErrorMessage = { header: 'Error generating outline', message: 'Try refreshing the page', type: 'error', imageUrl: null } 
      siteUi.showNotification(refreshingErrorMessage,10000);      
    });
  }

  function loadOutline(basePostUrl, postData=null, delay=500) {

    siteUi.showPageOverlay({label:"An outline is being generated...based on the keywords and current title",id:'renderOutline'});
    let formData;
    if(postData !== null)
    {
      formData = new FormData();
      for (var key in postData) {
        if (postData.hasOwnProperty(key)) {
          formData.append('post[' + key + ']', postData[key]);
        }
      }

    }
    if(postData == null) {
      setTimeout(()=>{generate.autoRunGeneration(basePostUrl,'outline',formData)},500)
    }
    else {
      const element = {id:'generate-outline'}
      const initialUrl = `${basePostUrl}${getActionUrl(element)}`;      
      generate.doGeneratePostAction(formData, element, initialUrl);
    }
    setTimeout(()=>{siteUi.hidePageOverlay({id:'renderOutline'})},15000)
  }

  // Exports
  window.generate = {
    getActionUrl,
    handleAutoPostSubmit,
    doGeneratePostAction,
    handleGeneratePostFormSubmit,
    autoRunGeneration,
    loadOutline, loadOutlineFromId
  };