// app/assets/javascripts/footer_functions.js

function doPageStatsLoad(refreshCache, url) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        success: (response) => {
          return resolve({ response: response })
        },
        error: (response) => {
          console.log(`Error fetching ${url}`)
          console.log(response)
          return reject({ msg: 'ERROR ajaxPromise 1', error: response })
        }
      });
    })
  
    return reject({ msg: `RARE SERVER ERROR fetching ${url}`, error: 'ERROR INFO' })
  }
  
  function doPageIdeasLoad(refreshCache, url) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        },
        success: (response) => {
          return resolve({ response: response })
        },
        error: (response) => {
          console.log(`Error fetching ${url}`)
          console.log(response)
          return reject({ msg: 'ERROR ajaxPromise 1', error: response })
        }
      });
    })
  
    return reject({ msg: `RARE SERVER ERROR fetching ${url}`, error: 'ERROR INFO' })
  }
  
  function loadAdminStats(url) {
    let res = doPageStatsLoad(false, url)
      .then((result) => {
        console.log(result)
        let cacheUsed = result.response.cache_used
  
        if (result.response.parts) {
          $("#subMenu").html(result.response.parts.sub_menu)
          $("#sideMenu").html(addDivWrap(result.response.parts.side_menu, cacheUsed, 'animate__animated animate__fadeInLeft'))
        } else {
          $("#adminTipsWrap").html(result.response.html)
          $("#adminTipsScroll").height($(window).height() - 100)
        }
      })
  }
  
  function loadAdminPage(loadAdminUrl) {
    let res = doPageIdeasLoad(false, loadAdminUrl)
      .then((result) => {
        console.log(result)
        let cacheUsed = result.response.cache_used
  
        if (result.response.parts) {
          $("#subMenu").html(result.response.parts.sub_menu)
          $("#sideMenu").html(addDivWrap(result.response.parts.side_menu, cacheUsed, 'animate__animated animate__fadeInLeft'))
        } else {
          $("#adminTipsWrap").html(result.response.html)
          $("#adminTipsScroll").height($(window).height() - 100)
        }
      })
  }  



window.adminUtility = {
  loadAdminPage, loadAdminStats
}