// readMoreModule.js

// Utility function to get cloned element
function getClone(el) {
    const wrapper = $('<div class="tempDivClone"></div>').appendTo('body')
      .css({ display: 'block', position: 'absolute', top: -10000, left: -10000, width: $(el).width() });
  
    const clone = el.clone().html(el.html());
    clone.appendTo(wrapper).find(readMoreSelector).removeClass(readMoreClass);
    $('.tempDivClone').find(readMoreSelector).removeClass(readMoreClass);
    return $('.tempDivClone');
  }
  
  // Define selectors and class names as variables
  const readMoreBtnSelector = '.readMoreBtn';
  const readMoreWrapSelector = '.readMoreWrap';
  const readMoreSelector = '.readMore';
  const readMoreClass = 'readMore';
  const readMoreBtnClass = 'readMoreBtn';
  const dFlexClass = 'd-flex';
  
  // Event handler to remove "read more" button on click
  function bindReadMoreButtons() {
    $(readMoreBtnSelector).on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      const readMoreWrap = $(this).closest(readMoreWrapSelector);
      readMoreWrap.find(readMoreSelector).removeClass(readMoreClass);
      $(this).hide().removeClass(dFlexClass);
      objEngaged($(this));
    });
  }
  
  // Event handler to remove .readMore if height is < 50 OR height > 150
  function bindReadMoreWraps() {
    $(readMoreWrapSelector).each(function () {
      const ht1 = $(this).height();
      const estimatedHeight = getClone($(this)).height();
      $(".tempDivClone").remove();
  
      if ((estimatedHeight - ht1) < 20) {
        $(this).removeClass(readMoreClass).find(`.${readMoreBtnClass}`).hide().removeClass(dFlexClass);
      }
  
      if (ht1 > 400) {
        $(this).find(readMoreSelector).removeClass(readMoreClass).find(`.${readMoreBtnClass}`).hide().removeClass(dFlexClass);
      }
    });
  }
  
  // Exports
  window.readMoreModule = {
    getClone,
    bindReadMoreButtons,
    bindReadMoreWraps
  };